<template>
  <div class="relative min-h-screen md:flex">
    <SideNavBar />

    <div class="flex-1 p-10 text-2xl">
      <!-- header -->
      <div class="flex justify-between flex-wrap md:flex-nowwrap items-center">
        <div class="flex">
          <ChevronLeftIcon
            @click="navigateToManageDoctor"
            class="h-7 w-7 pt-2 cursor-pointer"
          />
          <h2 class="font-semibold">Edit Doctor Info</h2>
        </div>
      </div>
      <!-- end of header -->

      <!-- content -->
      <div v-if="isLoading" class="loader"></div>

      <div v-if="!isLoading" class="mt-5">
        <form @submit.prevent="editDoctor" class="card pt-2 form-control">
          <label class="label"
            ><span class="label-text text-black"
              >Name <span class="text-red-500">*</span></span
            ></label
          >
          <input
            v-model="doctorName"
            type="text"
            class="input input-md input-bordered w-full"
            required
          />

          <label class="label"
            ><span class="label-text text-black"
              >Position <span class="text-red-500">*</span></span
            ></label
          >
          <input
            v-model="doctorPosition"
            type="text"
            class="input input-md input-bordered w-full"
            required
          />

          <label class="label"
            ><span class="label-text text-black">Credentials</span></label
          >
          <ckeditor
            v-model="doctorCredentials"
            class="text-xs"
            :editor="editor"
          ></ckeditor>

          <label class="label"
            ><span class="label-text text-black"
              >Clinical Interests</span
            ></label
          >
          <ckeditor
            v-model="doctorClinicalInterest"
            class="text-xs"
            :editor="editor"
          ></ckeditor>

          <label class="label"
            ><span class="label-text text-black">About</span></label
          >
          <ckeditor
            v-model="doctorAbout"
            class="text-xs"
            :editor="editor"
          ></ckeditor>

          <label class="label"
            ><span class="label-text text-black">Doctor Image</span></label
          >
          <div v-if="!checkIsFile" class="flex">
            <div class="flex-initial w-70">
              <img :src="`${$baseFileUrl}/${doctorImageSrc}`" alt="" />
            </div>
            <div class="flex-initial w-26 align-middle">
              <button
                @click="removeDoctorImage()"
                type="button"
                class="btn btn-sm bg-red-500 ml-1 border-none"
              >
                Remove
              </button>
            </div>
          </div>
          <DragDropImage
            v-if="checkIsFile"
            @changed="handleDoctorImage"
            :max="1"
            class="text-black"
            clearAll="Clear All"
            maxError="Maximum one file only"
          />

          <br />
          <button class="btn bg-green-500 border-none mt-3">Submit</button>
        </form>
      </div>
      <!-- end of content -->
    </div>
  </div>
</template>

<script>
import SideNavBar from "../../components/SideNavBar.vue";
import DragDropImage from "../../components/DragDropImage.vue";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

import { ChevronLeftIcon } from "@heroicons/vue/outline";

export default {
  name: "EditDoctor",
  props: ["doctorID"],
  components: { SideNavBar, DragDropImage, ChevronLeftIcon },
  data() {
    return {
      isLoading: true,
      editor: ClassicEditor,
      doctorName: "",
      doctorPosition: "",
      doctorCredentials: "",
      doctorClinicalInterest: "",
      doctorAbout: "",
      doctorImageSrc: [],
    };
  },
  mounted() {
    this.getDoctor();
  },
  methods: {
    getDoctor() {
      this.axios({
        url: "/doctor/getDoctor",
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
        params: {
          doctorID: this.doctorID,
        },
      })
        .then((response) => {
          this.doctorName = response.data.doctorName;
          this.doctorPosition = response.data.doctorPosition;
          this.doctorCredentials =
            response.data.doctorCredentials == null
              ? ""
              : response.data.doctorCredentials;
          this.doctorClinicalInterest =
            response.data.doctorClinicalInterest == null
              ? ""
              : response.data.doctorClinicalInterest;
          this.doctorAbout =
            response.data.doctorAbout == null ? "" : response.data.doctorAbout;
          this.doctorImageSrc = response.data.doctorImageSrc;
          this.isLoading = false;
        })
        .catch((error) => {
          if (error.response.status == 401) {
            this.$router.push({
              name: "Login",
              query: {
                error:
                  "Your account has been locked by admin. If you think this is a mistake, please contact your admin. Thank you.",
              },
            });
            this.$store.dispatch("createUserSession", null); // remove user state to prevent user from logging in
          }
        });
    },
    editDoctor() {
      this.isLoading = true;
      let formData = new FormData();
      formData.append("doctorID", this.doctorID);
      formData.append("doctorName", this.doctorName);
      formData.append("doctorPosition", this.doctorPosition);
      formData.append("doctorCredentials", this.doctorCredentials);
      formData.append("doctorClinicalInterest", this.doctorClinicalInterest);
      formData.append("doctorAbout", this.doctorAbout);
      formData.append("doctorImageSrc", this.doctorImageSrc);

      this.axios
        .post("/doctor/updateDoctor", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((response) => {
          this.$swal.fire({
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
            icon: "success",
            title: response.data["message"],
          });
          this.isLoading = false;
          this.navigateToManageDoctor();
        })
        .catch((error) => {
          if (error.response.status == 401) {
            this.$router.push({
              name: "Login",
              query: {
                error:
                  "Your account has been locked by admin. If you think this is a mistake, please contact your admin. Thank you.",
              },
            });
            this.$store.dispatch("createUserSession", null); // remove user state to prevent user from logging in
          }
        });
    },
    removeDoctorImage() {
      this.doctorImageSrc = "";
    },
    handleDoctorImage(files) {
      this.doctorImageSrc = files[0];
    },
    navigateToManageDoctor() {
      this.$router.push({ name: "ManageDoctor" });
    },
  },
  computed: {
    checkIsFile() {
      if (
        this.doctorImageSrc instanceof File ||
        this.doctorImageSrc == "" ||
        this.doctorImageSrc == null
      ) {
        // show dragDropImage when the value is a file or null or empty
        return true;
      } else {
        // of none of those condition meet, means the variable contains an url in server
        return false;
      }
    },
  },
};
</script>

<style>
@import "../../assets/CustomCKEditorStyle.css";
</style>